import { useState, useEffect } from 'react';
import { mapValues, isEmpty } from 'lodash';

export default (submitCallback, validation, initValuesState = {}, useTrim) => {
  const [values, setValues] = useState(initValuesState);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);

  const hasValidation = typeof validation === 'function';
  const hasSubmitCallback = typeof submitCallback === 'function';
  const hasErrors = !isEmpty(errors);

  useEffect(() => {
    if (!isSubmitting) return;

    Object
      .keys(errors)
      .forEach((key) => setTouched((prevState) => ({ ...prevState, [key]: true })));

    if (!Object.values(errors).length && hasSubmitCallback) {
      submitCallback(values, setErrors);
    }

    setSubmitting(false);
  }, [errors, isSubmitting, submitCallback, touched, validation, values, hasSubmitCallback]);

  useEffect(() => {
    if (Object.values(values).length && hasValidation) {
      setErrors(validation(values));
    }
  }, [validation, values, hasValidation]);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (hasValidation) {
      setErrors(validation(values));
    }
    setSubmitting(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues((prevState) => ({
      ...prevState,
      [name]: useTrim && typeof (value) !== 'object' ? value?.trim() : value,
    }));
  };

  const handleBlur = (e) => {
    const { name } = e.target;

    if (hasValidation) {
      setErrors(validation(values));
    }

    setTouched({ ...touched, [name]: true });
  };

  const handleFocus = (e) => {
    const { name } = e.target;
    setTouched({ ...touched, [name]: false });
  };

  const cleanUpForm = () => {
    setValues(initValuesState);
    setErrors({});
    setErrors({});
    setTouched({});
    setSubmitting(false);
  };

  const setTouchedAll = (untouched) => setTouched(mapValues(untouched, () => true));

  const touchField = (field) => {
    setTouched({ ...touched, [field]: true });
  };

  return {
    handleSubmit,
    handleChange,
    handleBlur,
    handleFocus,
    cleanUpForm,
    setValues,
    values,
    errors,
    touched,
    setTouchedAll,
    touchField,
    hasErrors,
  };
};
