import React, { useState } from 'react';
import ActiveIntegrationItem from 'components/views/Integrations/ActiveIntegrations/ActiveIntegrationItem';
import Block from 'components/common/Block';
import propTypes from 'prop-types';
import 'components/views/Integrations/ActiveIntegrations/styles.scss';
import { ACTIVE_INTEGRATION_STATUSES } from 'components/views/Integrations/constants/Constants';
import ConfirmationWindow from 'components/common/ConfirmationWindow';
import { updateIntegration } from 'api/integrationsAPI';
import useAsync from 'hooks/useAsync';
import NeedHelpLink from 'components/views/Integrations/NeedHelpLink';
import { MESSAGES } from 'constants/messages';
import { Box, Grid, Stack } from '@mui/material';
import { EXTERNAL_LINK } from 'constants/routes';

const SECONDARY_INTEGRATIONS = 'secondaryIntegrations';

const ActiveIntegrations = ({
  integrations, editIntegration, updateActiveIntegrationsList, readonly,
}) => {
  const [selectedIntegration, setSelectedIntegration] = useState(undefined);
  const integrationStatus = ACTIVE_INTEGRATION_STATUSES[selectedIntegration?.integration_status];

  const confirmActivation = async (integration) => {
    const data = {};
    const selectedIntegrationStatus = ACTIVE_INTEGRATION_STATUSES[integration.integration_status];

    if (selectedIntegrationStatus === ACTIVE_INTEGRATION_STATUSES.DISABLED) {
      data.integration_status = ACTIVE_INTEGRATION_STATUSES.OK;
    } else {
      data.integration_status = ACTIVE_INTEGRATION_STATUSES.DISABLED;
    }

    await updateIntegration({ integrationUUID: integration.source.uuid, data });
    updateActiveIntegrationsList();

    if (selectedIntegration) {
      setSelectedIntegration(undefined);
    }
  };

  const {
    execute: confirmActivationAsync,
    status: activationStatus,
  } = useAsync(confirmActivation);

  const activateIntegration = (item) => {
    setSelectedIntegration(item);
  };

  const confirmationDeactivation = integrationStatus !== ACTIVE_INTEGRATION_STATUSES.DISABLED;

  return (
    <>
      <div className="active-integrations__container">
        <Stack>
          {integrations.hasOwnProperty('primaryIntegrations') && (
            <Block
              header={MESSAGES.active_integrations}
              className="active-integrations__main-block"
            >
              <div className="active-integrations__column">
                <Grid container spacing={2}>
                  <Grid item xs={12} container spacing={2} className="active-integrations__container-header">
                    <Grid item xs={3} />
                    <Grid item xs={3}>
                      {MESSAGES.updated}
                    </Grid>
                    <Grid item xs={2}>
                      {MESSAGES.status}
                    </Grid>
                    <Grid item xs={2}>
                      {/* {MESSAGES.edit} */}
                    </Grid>
                    <Grid item xs={2}>
                      {MESSAGES.activate}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={4}>
                      {integrations.primaryIntegrations.map((item) => (
                        <ActiveIntegrationItem
                          key={item.source.uuid}
                          onEditClick={editIntegration.bind(this, item)}
                          integration={item}
                          activateIntegration={activateIntegration}
                          readonly={readonly}
                        />
                      ))}
                      {integrations.secondaryIntegrations.map((item) => (
                        <ActiveIntegrationItem
                          key={item.source.uuid}
                          onEditClick={editIntegration.bind(this, item)}
                          integration={item}
                          activateIntegration={activateIntegration}
                          readonly={readonly}
                        />
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </div>
            </Block>
          )}
          {integrations.hasOwnProperty(SECONDARY_INTEGRATIONS)
            && !integrations.secondaryIntegrations.length && (
            <Block
              type="large-header"
              header={MESSAGES.well_done}
              postHeader={MESSAGES.add_some_secondary_sources}
            >
              <Box display="flex" justifyContent="flex-end">
                <NeedHelpLink href={EXTERNAL_LINK.helpIntegrations} />
              </Box>
            </Block>
          )}
        </Stack>
      </div>
      <ConfirmationWindow
        warning={confirmationDeactivation}
        secondaryGhost
        requestStatus={activationStatus}
        content={confirmationDeactivation
          ? MESSAGES.source_will_be_deactivated
          : MESSAGES.source_will_be_reactivated}
        open={!!selectedIntegration}
        onCancel={() => {
          setSelectedIntegration(undefined);
        }}
        onConfirm={() => {
          confirmActivationAsync(selectedIntegration);
        }}
      />
    </>
  );
};

ActiveIntegrations.propTypes = {
  integrations: propTypes.oneOfType([
    propTypes.object,
  ]),
  editIntegration: propTypes.func,
  updateActiveIntegrationsList: propTypes.func,
  readonly: propTypes.bool,
};

ActiveIntegrations.defaultProps = {
  integrations: {},
  editIntegration: undefined,
  updateActiveIntegrationsList: undefined,
  readonly: null,
};

export default ActiveIntegrations;
