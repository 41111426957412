export const GUIDES = {
  H1: `The total number of orders placed in the current month. 
  
  This does not include refunded, partially refunded, cancelled, and unpaid orders.`, // H1 - Total Orders
  H2: `The sum of all orders' grand total for current month. 
  
  Grand total is calculated after taxes, shipping, and discounts.`, // H2 - Total Sales
  H3: `The difference between subtotal sales and total cost. 
  
  Gross profit cannot be calculated without sufficient cost data - threshold to satisfy calculation is 75% cost data fulfilment.
  
  Additionally, if threshold is satisfied, products with 0 cost are filtered out from the calculation.`, // H3 - Gross Profit
  H4: `The gross profit over the subtotal sales.
  
  The subtotal sales used in the calculation is filtered based on purchased products with 0 cost.`, // H4 - Gross Profit Margin
  H5: 'The average order grand total in the current month.', // H5 - Order Value (Avg.)
  H6: 'The average revenue generated by customer.', // H6 - Customer Revenue (Avg.)
  H7: 'The average value a customer is expected to generate over their lifetime.', // H7 - Customer LV (Avg.)
  H8: 'The average number of orders generated per customer.', // H8 - Customer Orders (Avg.)
  H9: `The rate at which sessions are converted into orders. 
  
  Sessions include a variety of actions such as page views, events, social interactions, and transactions.`, // H9 - Conversion Rate
  H10: 'The return rate is the percentual rate between total orders and returned orders.', // H10 - Order Return Rate
  H11: `The rate at which carts are abandoned at checkout.
  
  Users who add items to their shopping cart, but exit without completing the purchase are consider abandoned carts. 
  
  KPI unavailable for WooCommerce and Magento integrations due to lack of data.`, // H11 - Cart Abandonment Rate
  H12: `The total number of new customers that have made a purchase this month. 
  
  A new customer is a customer who has never made a purchase previously.`, // H12 - New Customers
  H13: `The total number of returning customers that have made a purchased this month. 
  
  A returning customer is a customer who has made a purchase in a previous month.`, // H13 - Returning Customers
  H14: 'The total number of previous customers that have churned.', // H14 - Churned Customers
  H15: 'The percentual rate of total active customers whom have churned.', // H15 - Churn Rate
  H16: 'The total number of unique users that have made a session in the shop.', // H16 - Unique Sessions
  H17: 'Discounts from coupons and likewise methods .', // H17 - Applied Discounts
  H18: 'The average quantity of products purchased per order.', // H18 - Average Order Items ## Update in intercom
  H19: 'The percentual rate of order grand totals that was lost to discounts.', // H19 - Applied Discount Rate ## Update in intercom
  H20: `Total Orders, Total Sales and metrics calculated based on these numbers (ROAS, AOV, CPC) are all subject to the attribution of revenue and sales done by the individual providers.
  
  Do not compare revenue generated by eg. Google with your total revenue for the same month. These numbers are from different sources.`,
  H21: `The sum of all orders' grand total for current month done by the new customers. 
  
  Grand total is calculated after taxes, shipping, and discounts.
  
  New customers are the customers who have done their first purchase in a current month.`, // H21 - New Customers Sales
  H22: `The sum of all orders' grand total for current month done by the returning customers. 
  
  Grand total is calculated after taxes, shipping, and discounts.
  
  Returning customers are the customers who have done their first purchase in one of the previous months`, // H22 - Returning Customers Sales
  H23: `Gross profit without shipping costs and transaction fees.
  Default Shipping cost is equal to 5.6€ per order.
  Default Transaction fee is equal to 1.2% of total revenue.
  
  Total profit cannot be calculated without sufficient cost data - threshold to satisfy calculation is 75% cost data fulfilment.
  
  Additionally, if threshold is satisfied, products with 0 cost are filtered out from the calculation.`, // H23 - Total Profit
  H24: `The total profit over the subtotal sales.
  
  The subtotal sales used in the calculation is filtered based on purchased products with 0 cost.`, // H24 - Total Profit Margin
  M1: 'Total number of ad conversions that resulted in an order.', // M1 - Total Orders
  M2: 'Total value of ad conversions that resulted in an order.', // M2 - Total Sales
  M3: 'Total number of clicks on ads.', // M3 - Total Clicks
  M4: 'Total number of times ad was displayed on a screen.', // M4 - Total Impressions
  M5: 'Total amount spent on running ads.', // M5 - Total Cost
  M6: 'Return on ad spend - Total Sales divided by Total Cost.', // M6 - ROAS
  M7: 'Average order value - Total Sales divided by Total Orders.', // M7 - AOV
  M8: 'Average cost per action - Total Cost divided by Total Orders.', // M8 - CPA
  M9: 'Average cost per click - Total Cost divided by Total Clicks.', // M9 - CPC
  M10: 'Cost per thousand impressions - Total Cost divided by Total Impressions and multiplied by 1000.', // M10 - CPM
  M11: 'Click through rate - Total Clicks divided by Total Impressions.', // M11 - CTR
  C1: 'Click on segments to filter customer list.', // C1-Segments
  C2: 'Create and edit filters to identify specific customer groups using customer information, performance and Custimy empowered metrics.',
  C3: 'Full single customer list. It can be filtered to see certain chosen parameters and how they correlate with the different segments.', // C3-Customer list
  CSV1: 'Basic information about a single customer, including whether they accept marketing or not.', // CSV1-Customer profile
  CSV2: 'Relevant personal information about the customer.', // CSV2-Personal Info
  CSV3: "This is the customer's total spending since they became customer.", // CSV3-Total Spent
  CSV4: 'Total profit earned from the customer. Based on sale of customer with taxes, shipping and product cost deducted.', // CSV4-Total Profit
  CSV5: 'Profit margin based on all time purchases made by the customer.', // CSV5-Profit Margin
  CSV6: 'The percentage of orders the customer has returned in their lifetime.', // CSV6-Return rate
  CSV7: 'The total amount of discounts the customer has received on purchases. ', // CSV7-Total Discounts
  CSV8: "This is the customer's average order value since they became a customer. ", // CSV8-Order size (avg)
  CSV9: `The probability that this customer will make a purchase in the next 30 days.
  
  This KPI is Machine Learning based. It will consider multiple factors and grow more precise over time.`,
  CSV10: "Customer's recent purchase activity and recent interaction with advertisements.", // CSV10-Recent Activity
  CSV11: 'Preferences of the customer, based on all time data.', // CSV11-Preferences
  CSV12: "The customer's most recent sessions and touch points.", // CSV12-Sessions
  CSV13: 'The most recently purchased products by this customer.', // CSV13 - Recently purchased products
  CSV14: `The predicted probability that this customer will churn and not place an order again before ceasing to be an active customer.
  
  This KPI is Machine Learning based. It will consider multiple factors and grow more precise over time.
  `,
  CSV15: `The amount of sales this customer is expected to generate over the coming 12 months.
  
  This KPI is Machine Learning based. It will consider multiple factors and grow more precise over time.`,
  CSV16: 'The subtotal sales is the revenue generated by this customer, excluding taxes and shipping.',
  CSV17: 'The total amount refunded to this customer through returns, charge-backs or compensations.',
  SO1: `This page provides an overview of the different customer segments.
  
  You can click on the different segments to go in depth with the data for the specific segment and learn more.`, // SO1 - Segments
  SO2: `The distribution of customers in each segment.
  
  Hover over each segment bar to see percentual distribution.`, // SO2 - Segment Split
  SO3: 'The highest spenders and most frequent customers.', // SO3 - Most Valuable Customers
  SO4: 'Customers on their way to becoming Most Valuable Customers.', // SO4 - Promising Customers
  SO5: "The core, 'middle of the pack' customers, that buy fairly often and with reasonable spend.", // SO5 - Core
  SO6: 'The customers who spend a lot, but are not that frequent.', // SO6 - High Spend Customers
  SO7: 'Customers who purchase consistently but have lower spend.', // SO7 - Loyal Low Spend
  SO8: 'Customers who have made their first order within the last 30 days.', // SO8 - New Customers
  SO9: 'Customers who buy less often, with lower spend and possibly high return rates.', // SO9 - Least Valuable Customers
  SO10: 'The number of customers who are not likely to return and make further purchases.', // SO10 - Departed Customers
  ISP1: 'Total amount of active customers within the segment.', // ISP1 - Customers
  ISP2: 'Total amount of placed and completed orders.', // ISP2 - Orders
  ISP3: 'Total sales since start, based on all available data.', // ISP3 - Total Sales
  ISP4: 'Total profit since start, based on all available data.', // ISP4 - Total profits
  ISP5: 'Order grand total average for customers in this segment of all time.', // ISP5 - Order Size (Avg.)
  ISP6: 'All time average amount of orders made per customer in the segment.', // ISP6 - Orders
  ISP7: 'Average profit per customer in this segment, based on all available data.', // ISP7 - Profit
  ISP8: "The sum of orders' grand totals for this month.", // ISP8 - Sales/month
  ISP9: 'Return rate for this segment of all time.', // ISP9 - Return
  ISP10: 'Amount of the sent emails that are bouncing in this segment.', // ISP10 - Email bounce rate
  ISP11: 'Sources that initiated purchase, both paid and organic.', // ISP11 - Customer Source
  ISP12: 'The percentual rate of conversions originating from organic traffic.', // ISP12 - Organic Traffic
  ISP13: 'The percentual rate of conversions originating from paid traffic.', // ISP13 - Paid Traffic
  ISP14: 'Customers in the segment making repeat purchases.', // ISP14 - Repeat rate
  ISP15: 'The demographic distribution of the customers in segment.', // ISP15 - Demographics
  INT1: `This is the webshop platform and the most important source of information for the CDP.
  
  This is the first integration that needs to be done.`, // INT1 - Primary source
  INT2: 'Adding the email marketing tool will provide details for behaviour analysis and preferred channel for the different segments', // INT2 - Email Marketing
  INT3: 'Information from social networks will help with information about paid ads and preferred  marketing channels.', // INT3 - Social Networks
  INT4: 'Information from Customer service tools will add valuable information for segments, churn etc.', // INT4 - Customer Service
  INT5: 'Analytics tool will provide with information regarding website and ad behaviour.', // INT5 - Analytics
  INT6: 'Ad providers will help to get a good overview of the impact of paid ads compared to organic traffic.', // INT6 - Ad provider
  A1: `Filter the results below. Search or add different filters.
  
  Click "New Audience" to begin creating a custom audience.`, // A1 - filter + new audience
  A3: `Existing audiences. Results can be filtered above.
  
  Switch audiences on, to start exporting data to the chosen integration.`, // A3 - Search results
  A4: `This is the heart of the activation layer. 
  On this page you create and activate audiences. It's easy! 
  
  Custom audiences are automatically integrated and synchronized with audiences in your channels.`, // A4 - Custom audiences ## Unused
  NA1: `On this page you create or edit custom audiences by segmenting and filtering your customer base. 
  
  Once a custom audience has been integrated with a channel, you can easily create targeted marketing in the chosen tool.`, // NA1 - Create custom audience
  NA2: `Audience type determines from which angle you want to find your customers.
  
  Choose Segment for customer centric targeting with product recommendation.
  
  Choose Product or Category to get audience targeting from specific products.`,
  NA3: 'Choose one or more segments to include in your custom audience.', // NA3 - Segment selector
  NA4: 'Create and edit filters to identify and include the best suited customers for your audience using customer information, performance and Custimy empowered metrics.',
  NA5: `Press "Add channels" to start integrating channels. 
  
  The custom audiences will be synchronized to the chosen channels when the custom audience is created or updated.`, // NA5 - Integrate with channels
  NA6: `The top recommended products for your custom audience, ranked in descending order.
  
  These products are recommended based on your entire store's historical data and the history of the customers in the audience using Machine-Learning.`,
  NA7: `This is the audience size in number of customers who fits the audience filters.
  
  This number, along with the audience in the integrated channels, will be updated every day as your customer base changes shape.`,
  PI1: 'Add filters by various types to explore your product selection in depth.',
  PI2: 'Click on product segments to filter products by.',
  PI3: `The filtered product list.
  
  Click on Change View to select columns displayed in the table list.
  
  Click on Export to download a CSV of product table results.`,
  PI4: 'Products generating low profits, with a low price to cost margin.',
  PI5: 'Products generating higher profits, with a high price to cost margin.',
  PI6: 'Top of the line products, generating high profits with proportionally high order amplitude.',
  PI7: 'Products that are quick to repeat in purchases from the same customers.',
  PI8: 'Products that create customers who subsequently convert more orders.',
  PI9: 'Products that are recently on the rise in order popularity.',
  PI10: 'Products that are declining in order popularity.',
  SP1: 'Basic product information and KPIs taken from orders and product catalog.',
  SP2: 'Revenue generated through placed and completed orders.',
  SP3: `Profit generated from sale of this product.
  
  Calculated from sum of sales of this product purchased with product's cost retracted.`,
  SP4: 'Number of products placed in orders and completed.',
  SP5: 'Number of returned products in completed orders.',
  SP6: 'Percentual rate of returns to purchased products.',
  SP7: 'Discounts applied to sales of this product.',
  SP8: 'Rate of customers who purchased this product more than one time in separate orders.',
  SP9: 'Rate of customers who purchased this product in their first order.',
  SP10: 'Rate of customers who purchased this product as the single product in their order.',
  SP11: 'List of other products who have often been purchased in same orders as this product.',
  SP12: `Distribution of customer segments who have purchased this product. Measured on life-time performance of product. 
  
  Useful for identifying which customer segments have a higher propensity for ordering this product.`,
  SP13: `Profit margin is the percentage of the product sales that are counted towards the bottom-line.
  
  Profit are calculated based on taxes, discounts, shipping and cost of goods being deducted from the generated sale.`,
  CI1: 'Add filters by various types to explore your category selection in depth.',
  CI2: `The filtered category list.
  
  Click on Change View to select columns displayed in the table list.
  
  Click on Export to download a CSV of category table results.`,
  SC1: 'Basic category information and KPIs calculated from orders and product catalog.',
  SC2: 'Revenue generated through placed and completed orders.',
  SC3: `Profit generated from category.
  
  Calculated from sum of sales of products purchased in this category with products' cost retracted.`,
  SC4: 'Number of products in category placed in orders and completed.',
  SC5: 'Number of returned products in this category.',
  SC6: 'Percentual rate of returns to purchased products in this category.',
  SC7: 'Discounts applied to sales of products in this category.',
  SC8: 'Percent of sales of products in this category that was lost to discounts being applied.',
  SC9: `Distribution of customer segments who have purchased products in this category. 
  
  Useful for identifying which customer segments have a higher propensity for certain categories of products.`,
  SC10: `Profit margin is the percentage of sales made from products in this category that are counted towards the bottom-line.
  
  Profit are calculated based on taxes, discounts, shipping and cost of goods being deducted from the generated sale.`,
  CC1: `Enabling Customer Consent will include only the customers who have given any type of consent for marketing / targeting on emails, phone, web or signed consent forms in the email marketing integrations.
  
  For Shopify integrations, this also includes whether they have accepted marketing directly in the webshop.`,
  CH1: `Explore your customer lifetime performance by utilizing the Custimy.io powered metrics to see beyond the bottom line.
  
  Create and organize your customer cohorts for you and your team to improve business forecasting and evaluate marketing strategies.`,
  CH2: `Search by name or add filters to find specific cohorts.
  
  Click "New cohort" to start creating new cohorts.`,
  CH3: "Here you'll find your existing cohorts organized by who created it, when it was updated and the amount of customers in the cohort.",
  CH4: `The number of months to display horizontally for each of the months in the vertical axis.

  For example, setting this to 24 months will display performance 24 months ahead from the first order date of each month.`,
  CH5: 'The start date to group by customers by.',
  CH6: 'The end date to group by customers by.',
  CH7: 'The main customer metric which will be displayed in the cohort.',
  CH8: `An optional field used to break down the main metric by customers or orders.
  
  The 'Customers' option includes the full count of customers in each vertical group.

  The 'Returning Customers' option includes only customers who placed an order in a given subsequent month.`,
  CH9: `The defining part of a cohort is the grouping of customers.
  
  Select one of the options, to either group customers together by when they first placed and order, or when they returned for the first time.`,
  EX1: 'Experiment group is being filled with customers. This process can take up to 10 minutes before it is ready.',
  EX2: 'Add filters to narrow down the customers you would like to run the experiment on.',
  EX3: `Create one or more experiment groups to run the experiment on.
  
  Customers will be randomly selected from the customers defined above.`,
};

export const SEGMENT_GUIDE_LIST = ['SO3', 'SO4', 'SO5', 'SO6', 'SO7', 'SO8', 'SO9', 'SO10'];
export const PRODUCT_SEGMENT_GUIDE_LIST = {
  1: 'PI4', 2: 'PI5', 3: 'PI6', 4: 'PI7', 5: 'PI8', 9: 'PI9', 10: 'PI10',
};
