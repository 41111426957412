import {
  SHIPPING_COST_ERROR_REQUIRED,
  SHIPPING_COST_CURRENCY_ERROR_REQUIRED,
  TRANSACTION_FEE_ERROR_REQUIRED,
} from 'constants/errors';

import { Validator } from 'utils';

export default ({
  shipping_cost, shipping_cost_currency, transaction_fee,
}) => {
  const errors = {};

  const shippingCostValidator = new Validator(shipping_cost);
  const shippingCostCurrencyValidator = new Validator(shipping_cost_currency);
  const transactionFeeValidator = new Validator(transaction_fee);

  try {
    shippingCostValidator.required(SHIPPING_COST_ERROR_REQUIRED)
  } catch (error) {
    errors.shipping_cost = error.message;
  }

  try {
    shippingCostCurrencyValidator.required(SHIPPING_COST_CURRENCY_ERROR_REQUIRED);
  } catch (error) {
    errors.shipping_cost_currency = error.message;
  }

  try {
    transactionFeeValidator.required(TRANSACTION_FEE_ERROR_REQUIRED)
  } catch (error) {
    errors.transaction_fee = error.message;
  }

  return errors;
};
