import React from 'react';
import { Link } from 'react-router-dom';

import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import validation from './signInValidation';
import { ROUTES } from 'constants/routes';
import {
  accessTokenLSManager, refreshTokenLSManager, userUuidLSManager,
} from 'helper/localStorage';
import useCurrentUser from 'hooks/useCurrentUser';
import { getPairOfToken } from 'api/authAPI';
import { MESSAGES } from 'constants/messages';
import BaseFooter from '../BaseFooter';
import ReactTooltip from 'react-tooltip';
import { size } from 'lodash';
import CustomFeedback from './CustomFeedback';
import Grid from '@mui/material/Grid';
import { GoogleLogin } from '@react-oauth/google';
import useGoogleSignIn from 'hooks/useGoogleSignIn';
import Divider from 'components/common/Divider';
import Block from 'components/common/Block';
import { ReactComponent as ShopifyIcon } from 'assets/svg/shopify.svg';


const GOOGLE_KEY = process.env.REACT_APP_GA_OAUTH_CLIENT_ID;

const SignIn = () => {
  document.title = `${MESSAGES.sign_in} - ${MESSAGES.custimy}`;
  const { setCurrentUserAsync } = useCurrentUser();

  const signInHandler = async ({ email, password }, setErrors) => {
    const requestBody = {
      email,
      password,
    };

    try {
      const pairOfTokens = await getPairOfToken(requestBody);

      if (pairOfTokens) {
        userUuidLSManager.set(pairOfTokens.uuid);
        accessTokenLSManager.set(pairOfTokens.access);
        refreshTokenLSManager.set(pairOfTokens.refresh);

        await setCurrentUserAsync();
      }
    } catch (authError) {
      setErrors(authError);
    }
  };

  const { execute, status } = useAsync(signInHandler);
  const {
    handleSubmit, handleChange, handleBlur, handleFocus, values, touched, errors,
  } = useForm(
    execute,
    validation,
  );

  const {
    onSuccess: onGoogleSuccess,
    onError: onGoogleError,
  } = useGoogleSignIn();

  const signInWithShopify = () => {
    window.location.href = process.env.REACT_APP_SHOPIFY_APP_URL;
  };

  const calculateToolTipPosition = (pos, e) => ({ left: (e.x - 45), top: e.y + 15 });

  return (
    <div className="container">
      <Grid
        item
        xs={12}
        sx={{ margin: { lg: 'auto 65px auto 225px', md: 'auto 65px auto 25px', xs: 'auto' } }}
      >
        <div className="container__main">
          <Block>
            <main className="main">
              <h1 className="main__title">{MESSAGES.sign_in}</h1>
              <div className="main__header">
                <p className="main__header__hint">
                  {MESSAGES.dont_have_an_account}
                  {' '}
                  <Link
                    to={ROUTES.signUp}
                    className="link authorization-title"
                  >
                    {MESSAGES.sign_up}
                  </Link>
                </p>
              </div>
              <form className="main__form form" onSubmit={handleSubmit} noValidate>
                <div className="socials-login-wrapper">
                  {/* <div className="shopify-button-wrapper" onClick={signInWithShopify}>
                    <ShopifyIcon className="shopify-icon" />
                    <span className="shopify-text">{MESSAGES.sign_in_with_shopify}</span>
                  </div> */}
                  <div className="google-button-wrapper">
                    <GoogleLogin
                      onSuccess={onGoogleSuccess}
                      onError={onGoogleError}
                      clientId={GOOGLE_KEY}
                      useOneTap
                      theme="filled_black"
                      auto_select
                      cancel_on_tap_outside={false}
                      width="300px"
                    />
                  </div>
                  <Divider />
                </div>
                {errors.detail && (
                <div className="form__error">
                  <CustomFeedback hint={{ body: errors.detail }} />
                </div>
                )}
                <div className="form__field">
                  <Input
                    id="email"
                    name="email"
                    type="email"
                    label={MESSAGES.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete="email"
                    error={errors.email}
                    touched={touched.email}
                    value={values.email}
                    isRequired
                    hasFeedback={!!values?.email?.length}
                    onFocus={handleFocus}
                  />
                </div>
                <div className="form__field form__field--last">
                  <Input
                    id="password"
                    name="password"
                    label={MESSAGES.password}
                    type="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    value={values.password}
                    error={errors.password}
                    touched={touched.password}
                    autoComplete="new-password"
                    isRequired
                    hasPreview
                    hasFeedback={!!values?.password?.length}
                  />
                </div>
                <div className="form__actions">
                  <div
                    className="form__actions__submit-sign-in"
                    data-tip
                    data-for="bottonTip"
                  >
                    <Button
                      type={BUTTON_TYPE.primary}
                      isDisabled={REQUEST_STATUS.loading === status}
                      fillWidth
                    >
                      {MESSAGES.sign_in}
                    </Button>
                  </div>
                  {!size(values) && (
                    <ReactTooltip
                      className="tooltip-container"
                      id="bottonTip"
                      place="right"
                      overridePosition={calculateToolTipPosition}
                      type="light"
                      effect="solid"
                      globalEventOff="click"
                      arrowColor="transparent"
                    >
                      <div className="tooltip-text">
                        {MESSAGES.please_fill_in_your_information}
                      </div>
                    </ReactTooltip>
                  )}
                </div>
                <div className="form__field form__field--additional">
                  <span>
                    {MESSAGES.forgot_your}
                    {' '}
                    <Link to={ROUTES.restorePassword} className="form__reset">
                      {MESSAGES.password_q}
                    </Link>
                  </span>
                </div>
              </form>
            </main>
          </Block>
          <BaseFooter />
        </div>
      </Grid>
    </div>
  );
};

export default SignIn;
