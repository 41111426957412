import { http } from 'utils';
import { ENDPOINTS } from 'constants/routes';
import { HTTP_METHODS } from 'constants/http';
import { ENTITY_INTEGRATION, ENTITY_USER } from 'constants/entities';

export const getLicense = () => http({
  url: ENDPOINTS.currentLicense,
  method: HTTP_METHODS.GET,
});

export const getAllLicense = () => http({
  url: ENDPOINTS.ownerLicenses,
  method: HTTP_METHODS.GET,
});

export const createLicense = ({
  company_name,
  business_category,
  company_url,
  company_size,
  set_empty,
}) => http({
  url: ENDPOINTS.createLicense,
  method: HTTP_METHODS.POST,
  data: {
    company_name,
    business_category,
    company_url,
    company_size,
    set_empty,
  },
});

const prepareTagsParams = (tags) => {
  const integrationUuids = tags
    .filter(({ entityKey }) => entityKey === ENTITY_INTEGRATION)
    .map(({ uuid }) => uuid);

  const userUuids = tags
    .filter(({ entityKey }) => entityKey === ENTITY_USER)
    .map(({ uuid }) => uuid);

  return `&integration_source_uuid__in=${integrationUuids.join(',')}&user_uuid__in=${userUuids.join(',')}`;
};

export const getLicensesList = ({
  page = 1, page_size = '', ordering, search = '', is_delete = false, tags = [],
}) => http({
  url: `${ENDPOINTS.license}?page=${page}&page_size=${page_size}&ordering=${ordering}&search=${search}&is_delete=${is_delete}${prepareTagsParams(tags)}`,
  method: HTTP_METHODS.GET,
});

export const updateLicense = ({
  uuid,
  company_name,
  business_category,
  company_url,
  company_size,
  avatar,
}) => {
  const data = new FormData();
  if (!avatar?.length && !!avatar) {
    data.append('avatar', avatar, 'avatar.png');
  } else {
    data.append('avatar', '');
  }

  data.append('company_name', company_name);
  data.append('business_category', business_category);
  data.append('company_url', company_url);
  data.append('company_size', company_size);
  return http({
    url: `${ENDPOINTS.license}${uuid}/`,
    method: HTTP_METHODS.PATCH,
    data,
  });
};

export const switchLicense = ({ licenseUuid }) => http({
  url: `${ENDPOINTS.switchLicense}${licenseUuid}/`,
  method: HTTP_METHODS.GET,
});

export const reactivateLicense = (uuid) => http({
  url: `${ENDPOINTS.licenseReactivate}${uuid}/`,
  method: HTTP_METHODS.GET,
});

export const getLicenseMembers = ({ licenseUUID }) => http({
  url: `${ENDPOINTS.licenseMembers}${licenseUUID}/`,
  method: HTTP_METHODS.GET,
});

export const deleteLicenseAction = ({ licenseUUID }) => http({
  url: ENDPOINTS.deleteLicense,
  method: HTTP_METHODS.DELETE,
  data: { license_uuid: licenseUUID },
});

export const deleteLicenseActionMember = ({ licenseDetailsUUID }) => http({
  url: ENDPOINTS.deleteLicenseMember,
  method: HTTP_METHODS.DELETE,
  data: { license_detail_uuid: licenseDetailsUUID },
});

export const updateLicenseMemberStatus = ({ licenseUUID, new_license_status }) => http({
  url: `${ENDPOINTS.updateLicenseMemberStatus}${licenseUUID}/`,
  method: HTTP_METHODS.PATCH,
  data: { new_license_status },
});

export const getYearlyRevenue = () => http({
  url: ENDPOINTS.annualRevenue,
  method: HTTP_METHODS.GET,
});

export const updateBusinessSettings = (uuid, businessSettings) => http({
  url: `${ENDPOINTS.license}${uuid}/`,
  method: HTTP_METHODS.PATCH,
  data: {business_settings: businessSettings},
});
