import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import Authorization from './Authorization';
import PrivateRoute from 'components/common/ProtectedRoutes/PrivateRoute';
import { REQUEST_STATUS } from 'constants/common';
import useCurrentUser from 'hooks/useCurrentUser';
import PrivateApp from 'containers/PrivateApp';
import AcceptInvitation from 'containers/AcceptInvitation';
import OneTimeLogin from 'containers/OneTimeLogin';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLicense } from 'store/license/getters';
import AdminPanel from 'containers/PrivateApp/AdminPanel';
import SuperUserRoute from 'components/common/ProtectedRoutes/SuperUserRoute';
import { Toasts } from 'components/common/Toast';
import { fetchActiveIntegrations } from 'store/license/licenseSlice';
import useChameleon from 'hooks/useChameleon';
import usePostHog from 'hooks/usePosthog';
import './styles.scss';
import { useIntercom } from 'react-use-intercom';

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

const App = () => {
  const { setCurrentUserAsync, currentUserStatus } = useCurrentUser();
  const { boot } = useIntercom();
  const currentLicense = useSelector(getCurrentLicense);
  const dispatch = useDispatch();
  useChameleon();
  usePostHog();

  useEffect(() => {
    setCurrentUserAsync();
    boot();
  }, []);

  useEffect(() => {
    dispatch(fetchActiveIntegrations());
  }, [currentLicense.uuid]);

  useEffect(() => {
  }, [currentUserStatus]);

  return currentUserStatus === REQUEST_STATUS.success && (
    <>
      <Toasts />
      <Route component={ScrollToTop} />
      <Switch>
        <Route
          path={[
            ROUTES.signIn,
            ROUTES.signUp,
            ROUTES.details,
            ROUTES.restorePassword,
          ]}
          component={Authorization}
        />
        <Route path={ROUTES.acceptInvitation} component={AcceptInvitation} />
        <Route path={ROUTES.oneTimeLogin} component={OneTimeLogin} />
        <SuperUserRoute path={ROUTES.adminPanel} component={AdminPanel} />
        <PrivateRoute path={ROUTES.portal} component={PrivateApp} />
      </Switch>
    </>
  );
};

export default App;
