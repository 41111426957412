import React, { useEffect } from 'react';
import 'containers/AcceptInvitation/styles.scss';
import useRouter from 'hooks/useRouter';
import { exchangeOneTimeToken } from 'api/authAPI';
import useCurrentUser from 'hooks/useCurrentUser';
import useLogout from 'hooks/useLogout';
import { accessTokenLSManager, refreshTokenLSManager, userUuidLSManager } from 'helper/localStorage';


const OneTimeLogin = () => {
  const { query } = useRouter();
  const logoutHandler = useLogout();
  const { setCurrentUserAsync } = useCurrentUser();

  useEffect(() => {
    const login = async () => {
      const {uidb64, token} = query;
      if (!uidb64 || !token) {
        logoutHandler();
      }

      try {
        const response = await exchangeOneTimeToken(uidb64, token);
        userUuidLSManager.set(response.uuid);
        accessTokenLSManager.set(response.access);
        refreshTokenLSManager.set(response.refresh);

        await setCurrentUserAsync();
      } catch (error) {
        logoutHandler();
      }
    };

    login();
  }, []);


  return <></>
};

export default OneTimeLogin;
