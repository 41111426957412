import moment from 'moment';
import { MESSAGES } from 'constants/messages';

export const dashboardActiveKpisKeys = [
  'total_orders',
  'total_sales',
  'new_customers_sales',
  'returning_customers_sales',
  'total_profit',
  'gross_profit',
  'gross_profit_margin',
  'profit_margin',
  'avg_order_size',
  'avg_total_sales',
  'clv',
  'avg_count_orders',
  'conversion_rate',
  'return_rate',
  'new_customers',
  'returned_customers',
  'churn_customers',
  'churn_rate',
  'unique_visitors',
  'abandonment_rate',
  'total_discounts',
  'avg_order_items',
  'applied_discount_rate',
];

export const metricsActiveKpisKeys = [
  'orders',
  'sales',
  'clicks',
  'impressions',
  'cost',
  'roas',
  'aov',
  'cpa',
  'cpc',
  'cpm',
  'ctr',
];
export const metricsActiveKpisPercentKeys = ['ctr'];

export const barChartColorSet = [
  '#ce6230', '#ce896a', '#c43e08', '#ee4500',
  '#a96648', '#804b33', '#5e2e16', '#522009', '#ce6230',
];

export const barChartFallbackColor = '#ce6230';

const getHexWithOpacity = (hex, alpha) => `${hex}${Math.floor(alpha * 255)
  .toString(16).padStart(2, 0)}`;

export const getMonthChartBackground = (color) => {
  const colorSet = {
    half: getHexWithOpacity(color, 0.9),
    quarter: getHexWithOpacity(color, 0.6),
    zero: getHexWithOpacity(color, 0.4),
  };

  const ctx = document.createElement('canvas').getContext('2d');
  ctx.canvas.height = 100;
  ctx.canvas.borderRadius = 5;

  const gradient = ctx.createLinearGradient(0, 25, 0, 300);
  gradient.addColorStop(0, colorSet.half);
  gradient.addColorStop(0.7, colorSet.quarter);
  gradient.addColorStop(1, colorSet.zero);
  return gradient;
};

export const currentMonthChartColor = (color = 'green') => {
  const shape = document.createElement('canvas');
  shape.width = 10;
  shape.height = 10;

  const c = shape.getContext('2d');
  c.strokeStyle = color;
  c.beginPath();
  c.moveTo(2, 0);
  c.lineTo(10, 8);
  c.stroke();
  c.beginPath();
  c.moveTo(0, 8);
  c.lineTo(2, 10);
  c.stroke();

  return c.createPattern(shape, 'repeat');
};

export const MONTHS_LABELS = Array(12).fill(0).map((item, idx) => moment().month(idx).format('MMM'));

export const PLATFORMS = {
  facebook: {
    title: MESSAGES.facebook,
    image: 'facebook_ads',
    integration: 'facebook_ads',
  },
  google_ads: {
    title: MESSAGES.google_ads,
    image: 'google',
    integration: 'google_ads',
  },
  instagram: {
    title: MESSAGES.instagram,
    image: 'instagram',
    integration: 'facebook_ads',
  },
  blended: {
    title: MESSAGES.blended,
    image: 'blended',
  },
};
