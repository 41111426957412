import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import 'components/views/Settings/LicenseSettings/License/styles.scss';
import useAsync from 'hooks/useAsync';
import useForm from 'hooks/useForm';
import Input from 'components/common/Input';
import Select from 'components/common/Select';
import Button from 'components/common/Button';
import { BUTTON_TYPE, REQUEST_STATUS } from 'constants/common';
import { useToast } from 'components/common/Toast';
import { updateBusinessSettings } from 'api/licenseAPI';
import { getCurrentLicense } from 'store/license/getters';
import { updateLicenseAction } from 'store/license/licenseSlice';
import { MESSAGES } from 'constants/messages';
import { Stack } from '@mui/material';
import businessSettingsValidation from 'components/views/Settings/LicenseSettings/BusinessSettings/businessSettingsValidation';


const CURRENCIES = ['DKK', 'USD', 'EUR', 'SEK', 'NOK']
  .map((currency) => ({value: currency, label: currency}));

const toFormValues = (values) => ({
  shipping_cost: values.shipping_cost,
  shipping_cost_currency: {
    value: values.shipping_cost_currency,
    label: values.shipping_cost_currency,
  },
  transaction_fee: values.transaction_fee * 100,
});

const fromFormValues = (values) => ({
  shipping_cost: Number(values.shipping_cost),
  shipping_cost_currency: values.shipping_cost_currency.value,
  transaction_fee: Number(values.transaction_fee) / 100,
});

const BusinessSettings = () => {
  const dispatch = useDispatch();
  const license = useSelector(getCurrentLicense);

  const { createToast } = useToast();

  const submit = async (formValues) => {

    const values = fromFormValues(formValues);

    const response = await updateBusinessSettings(license.uuid, values);
    dispatch(updateLicenseAction(response));

    createToast(MESSAGES.success, MESSAGES.business_settings_updated);
  };

  const { execute, status } = useAsync(submit);

  const {
    handleSubmit, handleChange, handleBlur, values, errors, touched, setValues, hasErrors
  } = useForm(execute, businessSettingsValidation);

  useEffect(() => {
    const formValues = toFormValues(license.business_settings);
    setValues(formValues);
  }, [license]);

  return (
    <form className="form" onSubmit={handleSubmit} noValidate>
      <Stack spacing={4} direction="column" marginTop={2} marginBottom={2}>
        <Stack spacing={1} direction="row">
          <Input
            id="shipping_cost"
            name="shipping_cost"
            type="number"
            label={MESSAGES.shipping_cost}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.shipping_cost}
            error={errors.shipping_cost}
            touched={touched.shipping_cost}
            isRequired
            hasFeedback
          />
          <Select
            name="shipping_cost_currency"
            label={MESSAGES.shipping_cost_currency}
            onChange={handleChange}
            onBlur={handleBlur}
            options={CURRENCIES}
            value={values.shipping_cost_currency}
            error={errors.shipping_cost_currency}
            touched={touched.shipping_cost_currency}
            isRequired
            hasFeedback
          />
        </Stack>
        
        <Input
          id="transaction_fee"
          name="transaction_fee"
          type="number"
          label={MESSAGES.transaction_fee}
          onChange={handleChange}
          onBlur={handleBlur}
          hasPercent
          value={values.transaction_fee}
          error={errors.transaction_fee}
          touched={touched.transaction_fee}
          isRequired
          hasFeedback
        />
      </Stack>
      
                
      <Stack spacing={1} direction="row" justifyContent="flex-end">
        <Button
          type={BUTTON_TYPE.primary}
          isDisabled={hasErrors || status === REQUEST_STATUS.loading}
          block
        >
          {MESSAGES.update}
        </Button>
      </Stack>
                    
    </form>
  );
};

export default BusinessSettings;
